<template>
  <b-container fluid>
    <b-row>
      <b-col xl="12" lg="12" sm="12">
            <iq-card>
                <template v-slot:body>
                    <b-overlay :show="formLoader" rounded="sm">
                        <b-row>
                            <b-col xl="12" lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                        <b-row>
                                            <b-col>
                                                <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="org_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="mainData.org_id"
                                                    :options="orgList"
                                                    id="org_id"
                                                    :disabled="mainData.org_id != ''"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col>
                                                <ValidationProvider name="Crop Type" vid="crop_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="crop_type_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('soil_management.crop_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="mainData.crop_type_id"
                                                    :options="cropTypeList"
                                                    id="crop_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col lg="6" sm="6">
                                                <ValidationProvider name="Crop Name" vid="crop_name_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="crop_name_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('soil_management.crop')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="mainData.crop_id"
                                                    :options="cropList"
                                                    id="crop_name_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col>
                                                <ValidationProvider name="Pest Type" vid="pest_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="pest_type_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('content_management.pest_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="mainData.pest_type"
                                                    :options="pestTypeList"
                                                    id="pest_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <ValidationProvider name="Pest Name" vid="pest_name_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="pest_name_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('content_management.pest_name')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="mainData.pest_name_id"
                                                    :options="pestNameList"
                                                    id="pest_name_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col>
                                                <ValidationProvider name="Upload Image" vid="upload_image_id" :rules="`${mainData.id ? '' : 'required'}`">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="upload_image_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('content_management.upload_image')}} <span v-if="mainData.id==null" class="text-danger">*</span>
                                                    </template>
                                                    <b-form-file
                                                    id="upload_image_id"
                                                    @change="processFile"
                                                    v-model="pestFile"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                    <div class="cust-feedback">
                                                        Please Upload png,gif,jpeg,jpg image
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <!-- ==========Start============ -->
                                        <b-row>
                                            <b-col lg="12" sm="12">
                                                <ValidationProvider name="Image Source" vid="image_src" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="2"
                                                    label-for="image_src"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('pest_management.image_source')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="image_src"
                                                    v-model="mainData.image_src"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <!-- ==========End============== -->
                                        <b-row>
                                            <b-col>
                                                <ValidationProvider name="Description" vid="description_id" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="description_id"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{$t('content_management.description')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-textarea
                                                    id="description_id"
                                                    v-model="mainData.description"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-textarea>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col></b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<style scope>
    .cust-feedback{
        width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
    }
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { pestGalleryStoreApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      this.mainData.org_id = this.$store.state.dataFilters.orgId
    if (this.id) {
      const tmp = this.getSoilInfoData()
      this.mainData = tmp
      this.mainData.edit_id = this.id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        formLoader: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        mainData: {
            type: 2,
            org_id: 0,
            crop_type_id: 0,
            crop_id: 0,
            pest_type: 0,
            pest_name_id: 0,
            image: '',
            description: '',
            edit_id: ''
        },
        pestFile: [],
        cropList: [],
        pestNameList: [],
        charactList: []
    }
  },
  watch: {
      'mainData.crop_type_id': function (newVal) {
          this.cropList = this.getCropList(newVal)
      },
      'mainData.pest_type': function (newVal) {
          this.pestNameList = this.getPestNameList(newVal)
      }
  },
  computed: {
      orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      cropTypeList: function () {
          const objectData = this.$store.state.VirtualMuseum.commonObj.cropTypeList.filter(item => item.status === 1)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      pestTypeList: function () {
          const objectData = this.$store.state.VirtualMuseum.commonObj.pestType
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      }
  },
  methods: {
    processFile (event) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.mainData.image = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.mainData.image = ''
        }
    },
    getSoilInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
        this.formLoader = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }

        result = await RestApi.postData(virtualMuseumServiceBaseUrl, pestGalleryStoreApi, this.mainData)
        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
                this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })

                this.$bvModal.hide('modal-3')
        } else {
                this.$refs.form.setErrors(result.errors)
        }
        this.formLoader = false
    },
    getCropList (typeId) {
        const objectData = this.$store.state.VirtualMuseum.commonObj.cropList.filter(item => item.status === 1 && item.type_id === typeId)
        return objectData.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.value, text: obj.text_bn }
            } else {
                return { value: obj.value, text: obj.text_en }
            }
        })
    },
    getPestNameList (typeId) {
        if (typeId === 1) {
            const objectData = this.$store.state.VirtualMuseum.commonObj.insectList.filter(item => item.status === 1 && item.crop_id === this.mainData.crop_id)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        }
        if (typeId === 2) {
            const objectData = this.$store.state.VirtualMuseum.commonObj.diseaseList.filter(item => item.status === 1 && item.crop_id === this.mainData.crop_id)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        }
    }
  }
}
</script>
