// pest gallery
export const pestGalleryListApi = '/content-management/pest-gallery/list'
export const pestGalleryStoreApi = '/content-management/pest-gallery/save'
export const pestGalleryStatusApi = '/content-management/pest-gallery/toggle-status'
// crop gallery
export const cropGalleryListApi = '/content-management/crop-gallery/list'
export const cropGalleryStoreApi = '/content-management/crop-gallery/save'

// manage gallery
export const manageGalleryListApi = '/content-management/gallery/list'
export const manageGalleryStoreApi = '/content-management/gallery/save'
